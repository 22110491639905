@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap");

$body-bg: #fff;
$body-color: #000;

$theme-colors: (
    "site-primary": #135974,
    "site-secondary": #9e2523,
    "site-gray": #f7f7f7,
    "site-dark": #666666,
    "site-rating": #feaa01,
    "warning-secondary": #ff8d2b,
);
@import "node_modules/bootstrap/scss/bootstrap";

body {
    font-family: "Arimo", sans-serif;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    //   border: none !important;
    border-color: #ced4da;
    background-image: none !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 254, 0.25);
    //   box-shadow: none;
}

.form-control:required:is-invalid {
    border: none !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat;
    box-shadow: none !important;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
h3 {
    font-size: 30px;
}
h4,
.h4 {
    font-size: 26px;
}
.fs--24 {
    font-size: 24px;
    @media (max-width: 767.98px) {
        font-size: 20px;
    }
}
.fs--18 {
    font-size: 18px;
    @media (max-width: 767.98px) {
        font-size: 14px;
    }
}
.fs--14 {
    font-size: 14px;
    @media (max-width: 420px) {
        font-size: 12px;
    }
}
h5,
.h5 {
    font-size: 20px;
}

h6 {
    font-size: 17px;
}
p {
    font-size: 15px;
}
label,
.label {
    font-size: 13px;
    font-weight: 600;
}

.fw-600 {
    font-weight: 600;
}
.border-2 {
    border-top: 2px solid;
}
.hrW-25 {
    width: 100px;
}

.extra_padding {
    padding-left: 150px !important;
    padding-right: 150px !important;
    @media (max-width: 1349.98px) {
        padding-left: 100px !important;
        padding-right: 100px !important;
    }
    @media (max-width: 1099.98px) {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    @media (max-width: 899.98px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    @media (max-width: 767.98px) {
        padding-left: 70px !important;
        padding-right: 70px !important;
    }

    @media (max-width: 557.98px) {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
    @media (max-width: 425.98px) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

//hero section

.bg-hero {
    background-image: url("../img/top\ bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    // height: 700px;
    .extra_paddingHero {
        padding-left: 250px !important;
        padding-right: 250px !important;
        @media (max-width: 2199.98px) {
            padding-left: 220px !important;
            padding-right: 220px !important;
        }
        @media (max-width: 1999.98px) {
            padding-left: 200px !important;
            padding-right: 180px !important;
        }
        @media (max-width: 1599.98px) {
            padding-left: 180px !important;
            padding-right: 50px !important;
        }
        @media (max-width: 1349.98px) {
            padding-left: 180px !important;
            padding-right: 50px !important;
        }
        @media (max-width: 1199.98px) {
            padding-left: 130px !important;
            padding-right: 50px !important;
        }

        @media (max-width: 992.98px) {
            padding-left: 50px !important;
            padding-right: 50px !important;
        }
        @media (max-width: 767.98px) {
            padding-left: 70px !important;
            padding-right: 70px !important;
        }

        @media (max-width: 557.98px) {
            padding-left: 50px !important;
            padding-right: 50px !important;
        }
        @media (max-width: 425.98px) {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }
    .contact_form {
        background-color: #fff;
        position: relative;
        border-top-left-radius: 100px;
        color: #000;
        height: 100%;
        @media (max-width: 1099.98px) {
            border-top-left-radius: 70px;
        }
        @media (max-width: 899.98px) {
            border-top-left-radius: 30px;
        }

        @media (max-width: 767.98px) {
            border-top-left-radius: 70px;
            border-top-right-radius: 70px;
        }
        @media (max-width: 557.98px) {
            border-top-left-radius: 50px;
            border-top-right-radius: 50px;
        }
        .form-control {
            border: 0;
            border-radius: 0;
            border-bottom: 2px solid;
        }
        .arrow {
            width: 35px;
            height: fit-content;
            margin-left: -35px;
            margin-top: 80px;
            @media (max-width: 1099.98px) {
                margin-top: 110px;
            }
            @media (max-width: 899.98px) {
                margin-top: 80px;
                margin-right: 10px;
            }
            @media (max-width: 499.98px) {
                display: none;
            }
        }
        .rating {
            &__google-logo {
                height: 30px;
                width: 30px;
            }
            &__rating {
                height: 37px;
                width: 115px;
            }
        }
    }
    .badge {
        position: absolute;
        top: 100px;
        left: -80px;
        color: white;
        background-color: theme-color("site-secondary");
        border-radius: 50%;
        padding: 10px;
        width: 150px;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1199.98px) {
            left: -90px;
            top: 80px;
        }
        @media (max-width: 1099.98px) {
            top: 50px;
        }
        @media (max-width: 992.98px) {
            top: 30px;
            width: 140px;
            height: 140px;
        }
        @media (max-width: 767.98px) {
            width: 120px;
            height: 120px;
            top: -60px;
            left: 60px;
        }
    }

    .badge_orange {
        z-index: 9999;
        position: absolute;
        top: 215px;
        left: -20px;
        color: white;
        background-color: #ff8d2b;
        border-radius: 50%;
        padding: 10px;
        width: 186px;
        height: 186px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        @media (max-width: 1199.98px) {
            left: -75px;
            top: 200px;
        }
        @media (max-width: 1099.98px) {
            top: 175px;
            left: -85px;
        }
        @media (max-width: 992.98px) {
            top: auto;
            width: 165px;
            height: 165px;
            bottom: 5px;
            left: -125px;
            .fs--18 {
                font-size: 16px;
            }
            .fs--24 {
                font-size: 21px;
            }
            .label {
                font-size: 11px;
            }
        }
        @media (max-width: 767.98px) {
            width: 145px;
            height: 145px;
            top: -70px;
            left: auto;
            right: 30px;
            .fs--18 {
                font-size: 14px;
            }
            .fs--24 {
                font-size: 18px;
            }
            .label {
                font-size: 10px;
            }
        }
    }
}

//Besparen
.besparen {
    color: #000;
    .besparen_bg {
        background-image: url("../img/Metaal-plafond.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 700px;
        @media (max-width: 767.98px) {
            height: 500px;
            background-size: 100% 100%;
        }
    }
}
.contentLeft__zorgt {
    font-size: 22px;
    margin-top: auto;
    margin-bottom: -2px;
}
.separator {
    background: white;
    height: 2px;
    width: 30%;
}
